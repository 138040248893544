@import "../../colors";

.DeletableMediaElement {
  position: relative;

  &__count {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 0 5px;
    color: $imageCountColor;
    background: $imageCountBackground;
  }

  &__delete {
    position: absolute;
    top: -10px;
    right: 0;
    padding: 0;

    &--error {
      &.ant-btn {
        background-color: $uploadErrorColor;
      }
    }

    &.ant-btn {
      height: 32px !important;
      width: 32px !important;
    }
  }
}