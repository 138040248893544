.rtl {
  .SubmissionProgress {
    padding: 10px 10px 10px 0;
  }
}

.SubmissionProgress {
  padding: 10px 0 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-progress-inner {
    flex: unset !important;
  }

  &__step {
    font-weight: 700;
  }
}