@import "../../colors";
@import "../../variables";

.RegionCountrySelector {
  margin: 0 var(--sidePadding) 0 calc(var(--sidePadding) + 40px);

  @media (max-width: $countrySelectionMobileMaxWidth) {
    margin: 0 var(--sidePadding) 0 var(--sidePadding);
  }

  &__headerDivider {
    @media (max-width: $countrySelectionMobileMaxWidth) {
      margin-top: 5px;
    }
  }

  &__regions {

    .ant-collapse-header {
      display: flex !important;
      flex-direction: row-reverse !important;
      justify-content: flex-end;
      color: $regionColor !important;
      align-items: baseline !important;
      padding-left: 12px !important;

      @media (max-width: $countrySelectionMobileMaxWidth) {
        padding-bottom: 0 !important;
      }

      .ant-collapse-expand-icon {
        margin-inline-start: 4px;
      }

      .ant-collapse-header-text {
        flex: unset !important;
        margin-inline-end: unset !important;
      }

    }

    .ant-collapse-content-box {
      padding-bottom: 0 !important;
    }

    &__region {
      margin: 0 0 10px 0;

      &__divider {
        margin: 0;
      }
    }
  }
}

.RegionHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: $regionColor;

  @media (max-width: $countrySelectionMobileMaxWidth) {
    font-size: 18px;
  }
}

.RegionContent {
  margin: 0 0 0 40px;

  @media (max-width: $countrySelectionMobileMaxWidth) {
    margin: 0 0 0 10px;
  }

}

.RegionCountry {
  display: flex;
  margin: 5px 0 28px 0;

  @media (max-width: $countrySelectionMobileMaxWidth) {
    flex-direction: column;
  }

  &__name {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    min-width: 265px;
  }

  &__locales {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    @media (max-width: $countrySelectionMobileMaxWidth) {
      margin: 16px 0 0 60px;
      flex-direction: column;
    }

    &__locale {
      min-width: 110px;
      color: $countryLocaleColor;
      font-size: 16px;
    }
  }
}