.CaseEnterBody {
  &__form {
    padding: 5px 15px 0 15px;
    margin: 15px auto 0 auto;
    max-width: 800px;

    &__bottomLine {
      justify-content: center;
      align-items: baseline;
      max-width: 80%;
      margin: auto;

      &__agreed {
        text-align: center;
        font-weight: 700;
      }

      &__submit {
        margin: 5px auto 10px auto;
        display: flex;

        &.ant-btn {
          white-space: normal;
          height: unset !important;
          padding-left: 13px;
          padding-right: 13px;
        }
      }
    }
  }
}