@import "../../colors";

.UploadErrorIcon {
  pointer-events: none;

  &.ant-btn {
    background-color: $uploadErrorColor;
    height: 21px !important;
    width: 21px !important;
    min-width: 0 !important;
  }
}