$mainFeatureColor: #00857c;
$semiTransparentMainFeatureColor: #00857c4d;
$almostTransparentMainFeatureColor: #00857c1d;
$darkGreenColor: #006760;
$lightGreen: #6DCDB2;
$superLightGreen: #ADCDB220;
$orange: #FF832B;
$white: #FFFFFF;
$black: #000000;
$blueishBlack: #0C2340;
$semiTransparentBlack: #000000e0;
$dark: #2D3648;
$darkGrey: #000000a3;
$transparentBlack: #000000A0;
$darkestGrey: #677179;
$greyBlue: #72767F;
$grey: #00000073;
$semiTransparentGray: #00000040;
$darkerGey: #C3C7CB;
$lightGrey: #F1F1F1;
$veryLightGrey: #F7F7F7;
$success: #93B727;
$highlight: #BD1A22;
$blue: #5450E4;
$error: #DB1E28;


$colorWarning: $orange;

$imagePlaceholderBorder: $dark;
$imagePlaceholderBackground: $lightGrey;
$imagePlaceholderLargeBorder: $darkerGey;
$imagePlaceholderLargeBackground: $lightGrey;
$imagePlaceholderLargeImageColor: $grey;

$pageBackgroundColor: $white;
$headerBackground: $white;
$backgroundColor: $white;
$headerBorderColor: $darkerGey;
$footerBorder: $black;

$languageColor: $mainFeatureColor;

$welcomeDescriptionColor: $white;
$welcomeDescriptionBackgroundColor: $mainFeatureColor;
$policiesBackgroundColor: $lightGrey;
$dpocIdIconColor: $grey;
$policyCardBackground: $white;
$carouselDot: $grey;
$carouselDotActive: $black;
$carouselDotBorder: $lightGrey;
$policiesInstructionLinkColor: $mainFeatureColor;
$infoIconColor: $mainFeatureColor;

$finishedStepDotColor: $mainFeatureColor;
$doColor: $darkGreenColor;
$dontColor: $highlight;

$imageExampleLabelColor: $darkestGrey;
$imageExampleBackgroundColor: $veryLightGrey;
$imageExampleBorderColor: $darkerGey;

$descriptionsText: $grey;
$descriptionsBackgroundColor: $veryLightGrey;
$descriptionsLabelColor: $blueishBlack;
$descriptionsTextColor: $blueishBlack;
$timeOutColor: $highlight;

$textColor: $black;
$titleTextColor: $blueishBlack;
$generalTextColor: $semiTransparentBlack;

$colorTextLabel: $darkGrey;
$emptyPhotosHeaderColor: $greyBlue;

$imageCountColor: $white;
$imageCountBackground: $transparentBlack;
$dpocTextColor: $blue;

$termsInfoColor: $white;
$termsInfoBackground: $mainFeatureColor;
$termsContentLinkColor: $mainFeatureColor;


$dragIconColor: $mainFeatureColor;
$dragDescriptionColor: $grey;

$switchColor: $mainFeatureColor;
$switchBackground: $superLightGreen;
$switchQRBackground: $lightGreen;
$switchQRColor: $white;

$notFoundHeaderColor: $colorWarning;
$errorBoundaryHeaderColor: $colorWarning;

$helpSentIconColor: $mainFeatureColor;

$countryLocationBorder: $semiTransparentMainFeatureColor;
$countryLocationBorderShadow: $semiTransparentGray;
$countryLocationPin: $mainFeatureColor;
$countryLocaleColor: $mainFeatureColor;
$regionColor: $mainFeatureColor;
$questionIconText: $transparentBlack;

$uploadErrorLinkColor: $mainFeatureColor;
$uploadErrorColor: $error;

$helpBackColor: $grey;
$faqItemDividerColor: $darkerGey;


:export {
  mainFeatureColor: $mainFeatureColor;
  almostTransparentMainFeatureColor: $almostTransparentMainFeatureColor;
}
