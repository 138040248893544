@import '../../colors';

.UploadedMedia {
  &__list {
    padding: 20px var(--sidePadding) 1px var(--sidePadding);

    &__media {
      margin: 20px 0 30px 0;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      .DeletableMediaElement {
        max-width: 300px;
        min-width: 250px;
      }
    }
  }
}