@import "../../colors";

.UploadFailedPlaceholder {
  border: 1px solid $uploadErrorColor;
  width: 98%;

  &__message {
    display: flex;
    gap: 10px;
    margin: 11px 0 11px 15px;
    color: $uploadErrorColor;
  }
}