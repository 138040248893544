@import "../../colors";

.MediaPlaceholder {
  display: flex;
  justify-content: center;
  width: 44px;
  height: 59px;
  border: 1px $imagePlaceholderBorder solid;
  background-color: $imagePlaceholderBackground;

  &--large, &--medium {
    border: 1px $imagePlaceholderLargeBorder solid;
    background-color: $imagePlaceholderLargeBackground;

    .anticon svg {
      width: 64px;
      height: 64px;
      color: $imagePlaceholderLargeImageColor;
    }
  }

  &--large {
    width: 98%;
    height: 200px;
  }

  &--medium {
    width: 100%;
    height: 145px;
  }

  &--borderless {
    border: unset;
  }
}