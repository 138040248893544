@import "../../colors";
@import "../../variables";

.TicketInformation {
  background-color: $descriptionsBackgroundColor;
  display: block;

  &__descriptions {
    padding: 2px var(--sidePadding) 10px var(--sidePadding);

    .ant-descriptions-item-content,
    .ant-descriptions-item-label {
      font-size: 16px;
    }
  }

  .ant-descriptions {

    .ant-descriptions-view {
      table {
        border-collapse: unset;
      }
    }

    .ant-descriptions-item {
      padding-bottom: 0;

      &:not(:last-child) {
        width: 400px;

        @media (max-width: $carouselMaxWidth) {
          width: unset;
        }
      }
    }

    .ant-descriptions-item-label {
      font-weight: 700;
      color: $descriptionsLabelColor;
    }

    .ant-descriptions-item-content {
      padding-bottom: 15px;
      color: $descriptionsTextColor;
    }
  }
}